import download from "downloadjs";
import { http } from "src/lib/http";

export interface IIceV3SVCSettings {
    DeviceId: number;
    StartDt: Date;
    EndDt: Date;
}

export async function getIceV3csvFile(iceV3SVCSettings: IIceV3SVCSettings, filename: string) {
    const { data } = await http.post("/icev3csv", iceV3SVCSettings, {
        responseType: "blob",
    });
    download(data, filename, "application/octet-stream");
}
