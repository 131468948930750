import {
    Flex,
    TextInput,
    NumberInput,
    Textarea,
    ColorInput,
    Button,
    SegmentedControl,
    Input,
    Switch,
    Select,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import React from "react";
import { useTranslation } from "react-i18next";
import { CreateChannelThresholdCommand, useCreateChannelThreshold } from "../api/createChannelThreshold";
import { UpdateChannelThresholdCommand, useUpdateChannelThreshold } from "../api/updateChannelThreshold";
import { ChannelThresholdDto, ThresholdDirectionEnum } from "../types";
import { useSelectedDevice } from "src/features/devices/hooks/useSelectedDevice";

type ChannelThresholdFormProps = {
    isEdit?: boolean;
    channelId: number;
    initialValue?: Partial<ChannelThresholdDto>;
    onCancel?: () => void;
    onSave?: () => void;
};

export const ChannelThresholdForm: React.FC<ChannelThresholdFormProps> = ({
    isEdit,
    initialValue: threshold,
    channelId,
    onCancel,
    onSave,
}) => {
    const { t } = useTranslation();
    const { mutateAsync: createAsync } = useCreateChannelThreshold();
    const { mutateAsync: updateAsync } = useUpdateChannelThreshold();
    const selectedDevice = useSelectedDevice();

    const form = useForm<UpdateChannelThresholdCommand | CreateChannelThresholdCommand>({
        initialValues: {
            Id: threshold?.Id || undefined,
            Color: threshold?.Color || "#F03E3E",
            DeviceChannelId: channelId,
            Description: threshold?.Description || "",
            Direction: threshold?.Direction || ThresholdDirectionEnum.Upper,
            ShowOnChart: true,
            Value: threshold?.Value || 0,
            MessageTemplate: threshold?.MessageTemplate ?? "",
            ImageEnabled: threshold?.ImageEnabled || false,
            ImageChannelId: threshold?.ImageChannelId || 0,
        },
    });

    return (
        <form
            onSubmit={form.onSubmit((values) => {
                onSave?.();
                isEdit ? updateAsync(values as UpdateChannelThresholdCommand) : createAsync(values);
            })}
        >
            <Flex gap="lg" direction="column">
                <TextInput label={t("Описание")} {...form.getInputProps("Description")} />

                <Input.Wrapper label={t("Значение")}>
                    <Flex align="center" gap="lg">
                        <SegmentedControl
                            value={String(form.values.Direction)}
                            onChange={(value) => form.setFieldValue("Direction", Number(value))}
                            data={[
                                {
                                    label: t("Больше или равно"),
                                    value: String(ThresholdDirectionEnum.Upper),
                                },
                                {
                                    label: t("Меньше или равно"),
                                    value: String(ThresholdDirectionEnum.Lower),
                                },
                                {
                                    label: t("Равно"),
                                    value: String(ThresholdDirectionEnum.Equals),
                                },
                            ]}
                        />
                        <NumberInput maw={150} {...form.getInputProps("Value")} precision={4} />
                    </Flex>
                </Input.Wrapper>

                <ColorInput
                    maw={150}
                    label={t("Цвет")}
                    swatchesPerRow={5}
                    format="hex"
                    withEyeDropper={false}
                    swatches={["#F03E3E", "#F59F00", "#37B24D"]}
                    {...form.getInputProps("Color")}
                />
                <Textarea label={t("Шаблон уведомления")} {...form.getInputProps("MessageTemplate")} />
                <Switch label={t("Отправлять график")} {...form.getInputProps("ImageEnabled", { type: "checkbox" })} />
                {/*вот это по человечески сделать, тут должны быть каналы устройства для выбора, отправить надо DeviceChannelId*/}
                {form.values.ImageEnabled ? 
                <Select
                    value={form.values.ImageChannelId.toString()} //{channels.values.toString()}
                    onChange={(value) => {
                        form.setFieldValue("ImageChannelId", Number(value));
                    }}
                    label={t("Выбор канала для графика")}
                    data={
                        selectedDevice?.DeviceChannels?.map((x) => ({
                            value: x.Id.toString(),
                            label: t(x.Name),
                        })) || []
                    }
                />
                : ''}
                <Flex justify="flex-end" gap="md">
                    <Button variant="default" onClick={onCancel}>
                        {t("Отмена")}
                    </Button>
                    <Button type="submit">{t("Сохранить")}</Button>
                </Flex>
            </Flex>
        </form>
    );
};

ChannelThresholdForm.displayName = "ChannelThresholdForm";
