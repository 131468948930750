import { showNotification } from "@mantine/notifications";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

import { axios } from "src/lib/axios";
import { MutationConfig, queryClient } from "src/lib/react-query";
import { ThresholdDirectionEnum } from "../types";

export interface CreateChannelThresholdCommand {
    Value: number;
    Description: string;
    Direction: ThresholdDirectionEnum;
    Color: string;
    ShowOnChart: boolean;
    DeviceChannelId: number;
    MessageTemplate: string;
    ImageEnabled: boolean;
    ImageChannelId: number;
}

export const createChannelThreshold = (data: CreateChannelThresholdCommand) => {
    return axios.post(`channels/${data.DeviceChannelId}/thresholds`, data);
};

type UseCreateChannelThresholdOptions = {
    config?: MutationConfig<typeof createChannelThreshold>;
};

export const useCreateChannelThreshold = ({ config }: UseCreateChannelThresholdOptions = {}) => {
    const { t } = useTranslation();
    return useMutation({
        onError: () => {
            showNotification({
                title: t("Ошибка!"),
                message: t(""),
                color: "red",
            });
        },
        onSuccess: (_, variables) => {
            queryClient.invalidateQueries(["channels/{channelId}/thresholds", variables.DeviceChannelId]);
            showNotification({
                title: t("Успех!"),
                message: t(""),
                color: "teal",
            });
        },
        ...config,
        mutationFn: createChannelThreshold,
    });
};
